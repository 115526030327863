<template>
  <div class="exception">
    <div class="img">
      <img src="/images/KpnpchXsobRgLElEozzI.svg"/>
    </div>
    <div class="content">
      <h1>404</h1>
      <div class="desc">抱歉，你访问的页面不存在或仍在开发中</div>
      <div class="action">
        <router-link to="/">
          <el-button type="primary">返回首页</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '404',
  data () {
    return {}
  }
}
</script>
<style>
  .exception {
    min-height: 500px;
    height: 80%;
    align-items: center;
    margin-top: 150px;
    display: flex;
    justify-content: center;
  }

  .exception .img {
    display: inline-block;
    padding-right: 52px;
    zoom: 1;
  }

  .exception .img img {
    height: 360px;
    max-width: 430px;
  }

  .exception .content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .exception .content h1 {
    color: #434e59;
    font-size: 72px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 24px;
  }

  .exception .content .desc {
    color: rgba(0, 0, 0, 0.45);
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
</style>
